import React, { FC, useState, useEffect } from "react";
import { DEMO_CAR_LISTINGS } from "data/listings";
import { CarDataType } from "data/types";
//import Pagination from "shared/Pagination/Pagination";
import Heading2 from "components/Heading/Heading2";
import CarCard from "components/CarCard/CarCard";
import FleetsCard2 from "./FleetsCard2";
// import FixedDepartureCard from "containers/FixedDeparturePage/components/FixedDepartureCard";


export interface SectionGridFilterCardProps {
  className?: string;
  data?: CarDataType[];
}

const DEMO_DATA: CarDataType[] = DEMO_CAR_LISTINGS.filter((_, i) => i < 12);

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  data = DEMO_DATA,
}) => {
  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading2
        heading="Our Fleets"
        className=" my-9"
      /* subHeading={
        <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
          233 cars
          <span className="mx-2">·</span>
          Aug 12 - 18
        </span>
      } */
      />
      
      <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {(data as any)?.map((car: any) => {
          return <FleetsCard2 key={car.id} data={car} size="small" />
        })}

      </div>

    </div>
  );
};

export default SectionGridFilterCard;
