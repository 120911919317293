
import React, { useState, useEffect } from 'react'
import '../components/cardComponent.css';
import { getAvatar } from 'services/apiServices';
import { useNavigate } from "react-router-dom";
import ButtonPrimary from 'shared/Button/ButtonPrimary';

const OneDaayTourCard = ({ data }: any) => {


  const [profileImg, setProfileImg] = useState("");
  const getProfile = async (filename: any) => {
    try {
      const profile: any = await getAvatar(filename);
      setProfileImg(URL.createObjectURL(profile))
    } catch (error) {
      // setProfileImg('../../../img/avatars/no_profile.jpg')
    }
  }

  useEffect(() => {
    getProfile(data.profileImg);
  }, [])

  const navGate = useNavigate()

  return (
    <div className="tour-card dark:bg-gray-900 shadow-lg justify-center" onClick={() => navGate(`/one-day-tour/view/${data.id}`)}>
      <div className="tour-card-image" style={{ backgroundImage: `url(${profileImg})` }}></div>
      <div className="tour-card-content">
        <h2 className=" font-semibold  text-xs text-center">{data.package_name}</h2>
        {/* <p>{data.itinerary}</p> */}
      </div>

      <div className="flex justify-center  items-center tour-card-content">
        <ButtonPrimary className=' justify-center rounded-md py-1'>View More</ButtonPrimary>

        {/* <div className='pb-5'>
        </div> */}
        {/* <span className="">
          {data.seats} seats
        </span>
        {data.price} */}
      </div>
    </div>
  )
}

export default OneDaayTourCard

