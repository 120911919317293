import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import FixedDepartureHero from "./components/FixedDepartureHero";
import FixedDepartureCardGrid from "./components/FixedDepartureCardGrid";
import { fixedDepartureApi } from "services/apiServices";

export interface FixedDeparturePageProps {
  className?: string;
}


const FixedDeparturePage: FC<FixedDeparturePageProps> = ({ className = "" }) => {


  const [fixedDepartureData, setFixedDepartureData] = useState<any>([]);

  const fetchFixedDepartureData = async () => {
    const response = await fixedDepartureApi()


    if (response.data) {
      let fDepTempData: any = [];
      if (response.data.response === "success") {
        console.log("Fixed Dep Data", response.data)
        response.data.fixedDeparture.map(async (item: any, key: number) => {
          fDepTempData[key] = {
            id: item.id,
            package_name: item.package_name,
            profileImg: item.image,
            seats:item.available_seats
          }
          // console.log("xxxx",fDepTempData)
          setFixedDepartureData(fDepTempData)
        })
      }
    }
  }


  useEffect(() => {
    fetchFixedDepartureData()
  }, [])

  return (
    <div
      className={`nc-FixedDeparturePage relative overflow-hidden ${className}`}
      data-nc-id="FixedDeparturePage"
    >
      <Helmet>
        <title>FIXED DEPARTURE | EUROPE TOURS & TRAVELS</title>
      </Helmet>

      <FixedDepartureHero />

      <div className="container relative  mt-4 md:mt-0 lg:mt-0 xl:mt-0">


        {fixedDepartureData.length > 0 &&
          <FixedDepartureCardGrid
            data={fixedDepartureData}
            className="pb-24 lg:pt-32 lg:pb-16" />
        }

      </div>

    </div>
  );
};



export default FixedDeparturePage