import React, { FC, useState,useEffect } from "react";
import { Helmet } from "react-helmet";
import TourPacageHero from "./components/TourPacageHero";
import TourPacageCardGrid from "./components/TourPacageCardGrid";
import { tourPacageApi } from "services/apiServices";

export interface TourPacagesPagePageProps {
  className?: string;
}




const TourPacagesPagePage: FC<TourPacagesPagePageProps> = ({ className = "" }) => {

    const [tourPacakData, setTourPackData] = useState<any>([]);

    const fetchTourPackData = async () => {
      const response = await tourPacageApi()

      if(response.data){
        let tourPackTempData: any = [];
        if(response.data.response === "success") {
          // console.log("Tpack Data", response.data)

          response.data.tourPackage.map(async(item:any, key:number) => {
            tourPackTempData[key] = {
              id:item.id,
              title:item.package_name,
              featuredImage:item.image,
              price:item.days
            }
           
            setTourPackData(tourPackTempData)
          }) 
        }
      }
    }

    useEffect(() => {
      fetchTourPackData()
    }, []);

  return (
    <div
      className={`nc-TourPacagesPagePage relative overflow-hidden ${className}`}
      data-nc-id="TourPacagesPagePage"
    >
      <Helmet>

        <title>TOUR PACKAGES | EUROPE TOURS & TRAVELS</title>

      </Helmet>
     
      <TourPacageHero />

      <div className="container relative mt-4 md:mt-0 lg:mt-0 xl:mt-0">
        {tourPacakData.length > 0 &&
          <TourPacageCardGrid
            data={tourPacakData}
            className="pb-24 lg:pt-32 lg:pb-16" />
        }
        
      </div>

    </div>
  );
};



export default TourPacagesPagePage