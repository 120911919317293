import React, { FC,useState,useEffect} from "react";
import NcImage from "shared/NcImage/NcImage";
import { TaxonomyType } from "data/types";
import { Link } from "react-router-dom";
import convertNumbThousand from "utils/convertNumbThousand";
import "../../containers/FixedDeparturePage/components/cardComponent.css"
import { getAvatar } from "services/apiServices";

export interface CardCategory3Props {
  className?: string;
  taxonomy: TaxonomyType;
}

const CardCategory3: FC<CardCategory3Props> = ({
  className = "",
  taxonomy,
}) => {
  const { count, name, href = "/", thumbnail } = taxonomy;

  const [profileImg, setProfileImg] = useState("");
  const getProfile = async (filename: any) => {
    try {
      const profile: any = await getAvatar(filename);
      setProfileImg(URL.createObjectURL(profile))
    } catch (error) {
      // setProfileImg('../../../img/avatars/no_profile.jpg')
    }
  }

  useEffect(() => {
    getProfile(profileImg);
  }, [])
  return (
    <Link
      to={href}
      className={`nc-CardCategory3 flex flex-col ${className}`}
      data-nc-id="CardCategory3"
    >
      <div className="tour-card dark:bg-gray-900 shadow-lg justify-center" style={{ height: '250px' }}>
        <div className="tour-card-image" style={{ backgroundImage: `url(${profileImg})` }}></div>
        <div className="tour-card-content">
          <h2 className="tour-text-bold">package_name</h2>

        </div>

      </div>
    </Link>
  );
};

export default CardCategory3;
