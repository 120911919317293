import Heading1 from 'components/Heading/Heading1';
import React from 'react'

// interface TimeLineItem {
//     title: string;
//     content: string;
// }

// interface TimelineProps {
//     items: any;

// }

const Timeline = ({ items, heading }: any) => {
    if (items.length === 0) return null;

    return (
        <>
            <Heading1
                heading={heading}
            />
            <main className="relative flex flex-col justify-center  overflow-hidden">
                <div className="w-full max-w-6xl mx-auto px-4 md:px-6 py-4">
                    <div className="flex flex-col justify-center divide-y divide-slate-200 [&>*]:py-1">
                        <div className="w-full max-w-3xl mx-auto">
                            {/* <!-- Vertical Timeline #3 space-y-8 relative before:absolute before:inset-0 before:h-full before:w-0.5 before:bg-gradient-to-b before:from-transparent before:via-slate-300 before:to-transparent --> */}
                            <div className="space-y-8 relative before:absolute before:inset-0 before:ml-5 before:-translate-x-px md:before:ml-[1.25rem]  md:before:translate-x-0 before:h-full before:w-0.5 before:bg-gradient-to-b before:from-transparent before:via-slate-300 before:to-transparent">
                                {items.map((item:any, index:number) => (
                                    <div className="relative">
                                        <div className="md:flex items-center md:space-x-4 mb-3">
                                            <div className="flex items-center space-x-4 md:space-x-2 md:space-x-reverse">
                                                {/* <!-- Icon --> */}
                                                <div className="flex items-center justify-center w-10 h-10 rounded-full bg-white shadow md:order-1">
                                                    <span >
                                                        {index + 1}
                                                    </span>
                                                </div>
                                                {/* <!-- Date --> */}
                                                {/* <time className="font-caveat font-medium text-xl text-indigo-500 md:w-28">Apr 7, 2024</time> */}
                                            </div>
                                            {/* <!-- Title --> */}
                                            <div className="text-slate-500 ml-14"><span className="text-slate-900 font-bold text-lg">{item.title}</span></div>
                                        </div>
                                        {/* <!-- Card --> */}
                                        <div className=" p-4 rounded border border-slate-100 text-slate-500  ml-7 md:ml-14 text-sm">{item.content}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default Timeline