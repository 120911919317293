import React, { FC, useEffect, useState } from "react";
import { DEMO_CAR_LISTINGS } from "data/listings";
import { CarDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { getAvatar } from "services/apiServices";
import { useNavigate } from "react-router-dom";

export interface TourPacageCardProps {
    className?: string;
    data?: CarDataType;
    size?: "default" | "small";
}

const DEMO_DATA: CarDataType = DEMO_CAR_LISTINGS[0];

const TourPacageCard: FC<TourPacageCardProps> = ({
    size = "default",
    className = "",
    data = DEMO_DATA,
}) => {
    const {
        featuredImage,
        title,
        href,
        price,
        seats,
    } = data;



    const [avatarFile, setAvatarFile] = useState<any>([]);
    const [profileImg, setProfileImg] = useState<any>("");

    const getProfile = async(filename: any) => {
        try {
            const profile: any = await getAvatar(filename);
		    setProfileImg(URL.createObjectURL(profile))
        } catch (error) {
            setProfileImg("")
        }		
	}

	useEffect(() => {
        if(avatarFile.length === 0){
		    getProfile(featuredImage);
        }
	},[featuredImage])

    const navGate = useNavigate()


    const renderSliderGallery = () => {
        return (
            <div className="relative w-full  overflow-hidden">
                <div className="aspect-w-16 aspect-h-9 ">
                    <NcImage
                        containerClassName="flex items-center justify-center"
                        className="w-full"
                        src={profileImg}
                    />
                </div>
            </div>
        );
    };

    const renderContent = () => {
        return (
            <div className={size === "default" ? " p-5  space-y-4" : "p-5 space-y-2"}>
                <div className="space-y-2">
                    <div className="flex items-center space-x-2 text-center justify-center">

                        <h2
                            className={`  capitalize ${size === "default"
                                ? "text-xl font-semibold"
                                : "text-base font-medium"
                                }`}
                        >
                            <span className="line-clamp-1 ">{title}</span>
                        </h2>
                    </div>
                </div>
                {/* <div className="flex justify-between">
                    <span className="border text-basegi font-semibold border-neutral-200 dark:border-neutral-700 rounded-lg overflow-hidden  dark:bg-neutral-900 px-7  py-1">
                        {price} Day
                    </span>

                    <span className="text-base font-semibold relative border border-neutral-200 dark:border-neutral-700 rounded-lg overflow-hidden  dark:bg-neutral-900 px-7 py-1">
                        {seats} seats
                    </span>
                </div> */}
                <div className="flex justify-center border-neutral-200 dark:border-neutral-700 rounded-lg">
                    <ButtonPrimary onClick={() => navGate(`/tour-pacage/view/${data.id}`) } className=" rounded-md py-2">View More</ButtonPrimary>
                </div>
            </div>
        );
    };

    return (
        <div
            className={`nc-TourPacageCard group relative border border-neutral-200 dark:border-neutral-700 rounded-lg overflow-hidden hover:shadow-xl transition-shadow bg-white dark:bg-neutral-900 will-change-transform ${className}`}
            data-nc-id="TourPacageCard"
        >
           
                {renderSliderGallery()}
                {renderContent()}
            
        </div>
    );
};

export default TourPacageCard;
