import axios from "axios";
import config from "../config/config.json"
import getAccessToken from './AccessToken';
// import { resolve } from "path";
// import { reject } from "lodash";



/* const header=()=>{
    const token=localStorage.getItem("access-token");
    return {
        headers:{
            'Authorization':`Bearer ${token}`
        }
    }

} */

export async function getAvatar (data) {
    return new Promise((resole, reject) => {
        axios({
            url : `${config.SERVER_URL}/s3/download/${data}`,
            method : "GET",
            responseType : "blob"
        }).then((res) => {
            let blobfile = new File([res.data], "Profile")
            resole(blobfile)
        }).catch((err) => {
            reject(err)
        })
    })
}

//Get All Packages
export async function getAllPackages() {
    return new Promise((resole, reject) => {
        axios.get(`${config.SERVER_URL}/packages/list`,
            {
                headers: {
                    'Authorization': `Bearer ${getAccessToken()}`
                }
            }).then((res) => {
                resole(res)
            }).catch((err) => {
                reject(err)
            })
    })
}
//Get All Services
export async function getAllServices() {
    return new Promise((resole, reject) => {
        axios.get(`${config.SERVER_URL}/services/list`,
            {
                headers: {
                    'Authorization': `Bearer ${getAccessToken()}`
                }
            }).then((res) => {
                resole(res)
            }).catch((err) => {
                reject(err)
            })
    })
}
//Create Contact
export const contactApi = (data) => {
    return new Promise((resolve,reject)=>{
        axios.post(`${config.SERVER_URL}/contact/create`,data)
        .then((res) =>{
            resolve(res)
        }).catch((res) => {
            reject(res)
        })
    })

}

//Create One Ways
export const onewayApi = (data) => {
    return new Promise((resolve,reject)=>{
        axios.post(`${config.SERVER_URL}/oneway/create`,data)
        .then((res) =>{
            resolve(res)
        }).catch((res) => {
            reject(res)
        })
    })

}

//VanTours

export const vanToursApi = () => {
    return new Promise((resolve,reject)=>{
        axios.get(`${config.SERVER_URL}/products/vantour/list`)
        .then((res) =>{
            resolve(res)
        }).catch((res) => {
            reject(res)
        })
    })

}

// Fleets  /fleets/list

export const ourFleetsApi = () => {
    return new Promise((resolve,reject)=>{
        axios.get(`${config.SERVER_URL}/fleets/list`)
        .then((res) =>{
            resolve(res)
        }).catch((res) => {
            reject(res)
        })
    })

}

// Fixed Departure

export const fixedDepartureApi = () => {
    return new Promise((resolve,reject)=>{
        axios.get(`${config.SERVER_URL}/products/fixed_departure/list`)
        .then((res) =>{
            resolve(res)
        }).catch((res) => {
            reject(res)
        })
    })

}

//Tour Pacages

export const tourPacageApi = () => {
    return new Promise((resolve,reject)=>{
        axios.get(`${config.SERVER_URL}/products/tourpackage/list`)
        .then((res) =>{
            resolve(res)
        }).catch((res) => {
            reject(res)
        })
    })

}

//Oneday Tour

export const oneDayToursApi = () => {
    return new Promise((resolve,reject)=>{
        axios.get(`${config.SERVER_URL}/products/one_day_tour/list`)
        .then((res) =>{
            resolve(res)
        }).catch((res) => {
            reject(res)
        })
    })

}


export async function getFixedDepartureById (id) {
    return new Promise((resole, reject) => {
        axios.get(`${config.SERVER_URL}/products/fixed_departure/${id}`,
        {headers: {
                'Authorization': `Bearer ${getAccessToken()}`
            }
        }).then((res) => {
            resole(res)
        }).catch((err) => {
            reject(err)
        })
    })
}

export async function getVanToursById (id) {
    return new Promise((resole, reject) => {
        axios.get(`${config.SERVER_URL}/products/vantour/${id}`,
        {headers: {
                'Authorization': `Bearer ${getAccessToken()}`
            }
        }).then((res) => {
            resole(res)
        }).catch((err) => {
            reject(err)
        })
    })
}

export async function getTourPacksById (id) {
    return new Promise((resole, reject) => {
        axios.get(`${config.SERVER_URL}/products/tourpackage/${id}`,
        {headers: {
                'Authorization': `Bearer ${getAccessToken()}`
            }
        }).then((res) => {
            resole(res)
        }).catch((err) => {
            reject(err)
        })
    })
}

export async function getOneDayToursById (id) {
    return new Promise((resole, reject) => {
        axios.get(`${config.SERVER_URL}/products/one_day_tour/${id}`,
        {headers: {
                'Authorization': `Bearer ${getAccessToken()}`
            }
        }).then((res) => {
            resole(res)
        }).catch((err) => {
            reject(err)
        })
    })
}



export async function getFleetsById(id) {
    return new Promise((resole, reject) => {
        axios.get(`${config.SERVER_URL}/fleets/${id}`, {
            headers: {
                'Authorization': `Bearer ${getAccessToken()}`
            }
        }).then((res) => {
            resole(res);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function getAllDestination (query = '') {
    return new Promise((resole, reject) => {
        axios.get(`${config.SERVER_URL}/one_day_destination/list`,
        {headers: {
                'Authorization': `Bearer ${getAccessToken()}`
            }
        }).then((res) => {
            resole(res)
        }).catch((err) => {
            reject(err)
        })
    })
}

export async function getOneDayToursByCity (data) {
    return new Promise((resole, reject) => {
        axios.post(`${config.SERVER_URL}/products/one_day_tour/bycity`, data,
        {headers: {
                'Authorization': `Bearer ${getAccessToken()}`
            }
        }).then((res) => {
            resole(res)
        }).catch((err) => {
            reject(err)
        })
    })
}


