import React, { useEffect, useState, FC } from "react";
import { Helmet } from "react-helmet";
import VantourHero from "./components/VantourHero";
import VantourCardgridCard from "./components/VantourCardgrid";
import { vanToursApi } from "services/apiServices";


export interface PageVantoursProps {
  className?: string;
}


const PageVantours: FC<PageVantoursProps> = ({ className = "" }) => {


  const [vanTourData, setvanTourData] = useState<any>([])

  const fetchData = async () => {
    const response = await vanToursApi()

    if (response.data) {
      let tempData: any =[];
      if (response.data.response === "success") {
        console.log("Van tours data", response.data)
        
        response.data.vanTour.map(async (item: any, key: number) => {
          tempData[key] = {
            id: item.id,
            title: item.package_name,
            featuredImage: item.image
          }
          // console.log("restData", tempData)
          setvanTourData(tempData)
        })
      }
    }
  }

  useEffect(()=>{
    fetchData()
  },[]);
  


  return (
    <div
      className={`nc-PageVantours relative overflow-hidden ${className}`}
      data-nc-id="PageVantours"
    >
      <Helmet>
        <title>VAN TOURS | EUROPE TOURS & TRAVELS</title>
      </Helmet>
     
      <VantourHero />

      <div className="container relative mt-4 md:mt-0 lg:mt-0 xl:mt-0">
        {vanTourData.length > 0 &&
          <VantourCardgridCard 
          data={vanTourData}
          className="pb-24 lg:pt-32 lg:pb-16" />
        }
      </div>
   

    </div>
  );
};



export default PageVantours