import React, { FC, useEffect, useState } from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
import SearchDropdown from "./SearchDropdown";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import HeroSearchForm2MobileFactory from "components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory";
import AvatarDropdown from "./AvatarDropdown";

export interface MainNav1Props {
  className?: string;
}

const MainNav1: FC<MainNav1Props> = ({ className = "" }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  console.log("login........................", isLoggedIn)
  useEffect(() => {
    if (localStorage.getItem("user-info")) {
      setIsLoggedIn(true)
    }
  }, [])
  return (
    <div className={`nc-MainNav1 relative z-10 ${className}`}>
      <div className="px-4 lg:container py-4 lg:py-5 relative flex justify-start">
        <Logo className="h-10 mb-8"  />
        <div className="hidden md:flex justify-end flex-1 items-center space-x-4 sm:space-x-10">

          <Navigation />
        </div>
        <div className="md:flex flex-shrink-0 justify-items-endflex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
          <div className="hidden lg:flex xl:flex items-center space-x-0.5">
            {/* <SwitchDarkMode /> */}
            {/* <SearchDropdown /> */}
            {/* <NotifyDropdown /> */}
            {/* <CartDropdown /> */}

            <div className="px-1" />
            {/* <span className="flex flex-row">
              <AvatarDropdown  /> <span className="mt-2 ml-2 text-sm"></span>
            </span> */}

            <div className="px-1 " />

            {/* <ButtonPrimary className=" rounded-md py-2 px-4 xl:px-5" href="/login">SignIn</ButtonPrimary>
              <ButtonPrimary className=" rounded-md py-2 px-4 xl:px-5" href="/signup">SignUp</ButtonPrimary> */}

          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
