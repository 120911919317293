import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage3";
import React, { FC, useState, useEffect } from "react";
//import SectionGridFilterCard from "./SectionGridFilterCard";
import { Helmet } from "react-helmet";
//import { getAllPackages } from "services/apiServices";
import { TaxonomyType } from "data/types";
//import Pagination from "shared/Pagination/Pagination";
import Heading3 from "components/Heading/Heading3";
import war from "./FilmImages/war.jpg";
import oop from "./FilmImages/oop.jpg";
import rou from "./FilmImages/rou.jpg";
import tho from "./FilmImages/tho.jpg";
import rab from "./FilmImages/rab.jpg";
import abb from "./FilmImages/abb.jpg";
import vas from "./FilmImages/vas.jpg";
import yam from "./FilmImages/yam.jpg";
import FlimHero from "./FlimHero";

export interface ListingStayPageProps {
  className?: string;
}
const DEMO_CATS: TaxonomyType[] = [
  {
    id: "1",
    href: "/listing-stay",
    name: "WAR",
    taxonomy: "category",
    thumbnail: war,
  },
  {
    id: "222",
    href: "/listing-stay",
    name: "THOZHA",
    taxonomy: "category",
    thumbnail: tho,
  },
  {
    id: "3",
    href: "/listing-stay",
    name: "OOPIRI",
    taxonomy: "category",
    thumbnail: oop,
  },
  {
    id: "4",
    href: "/listing-stay",
    name: "RABHASA",
    taxonomy: "category",
    thumbnail: rab,
  },

  {
    id: "222",
    href: "/listing-stay",
    name: "ABBAYITHU AMMAYI",
    taxonomy: "category",
    thumbnail: abb,
  },
  {
    id: "3",
    href: "/listing-stay",
    name: "VASTUPRAKARA",
    taxonomy: "category",
    thumbnail: vas,
  },
  {
    id: "4",
    href: "/listing-stay",
    name: "YAMALEELA II",
    taxonomy: "category",
    thumbnail: yam,
  },
  {
    id: "5",
    href: "/listing-stay",
    name: "ROUGH",
    taxonomy: "category",
    thumbnail: rou,
  },
];

const ListingTourPage: FC<ListingStayPageProps> = ({ className = "" }) => {
  /*  const [packages, setPackages] = useState<any>([]);
   console.log('........................packages',packages)
 
   const getPackageData = async () => {
     const response = await getAllPackages()
     if (response.data) {
       let tempData: any = [];
       if (response.data.response === "success") {
         response.data.packages.map((item: any, key: number) => {
           tempData[key] = {
             id: item.id,
             href: "#",
             name: item.name,
             country: item.country,
             days: item.days,
             image:item.image,
             description: item.description,
             taxonomy: "category",
             count: 188288,
           }
         })
         setPackages(tempData)
       }
 
     }
   }
   useEffect(() => {
     getPackageData()
   }, []) */
  return (
    <div
      className={`nc-ListingStayPage relative overflow-hidden ${className}`}
      data-nc-id="ListingStayPage"
    >
      <Helmet>
        <title>FILM PRODUCTION | EUROPE TOURS & TRAVELS</title>
      </Helmet>
      <BgGlassmorphism />

      {/* Hero Section  */}
      
      <FlimHero />

      <div className="container relative overflow-hidden ">
        {/* SECTION HERO */}
        {/* <SectionHeroArchivePage
          currentPage="Stays"
          currentTab="Own-way"
          className="pt-10 pb-24 lg:pb-28 lg:pt-16 "
        /> */}

       
        {/* SECTION */}
        <div
          className={`nc-SectionGridFilterCard mt-24 mb-24 lg:space-y-28 lg:mb-28 ${className}`}
          data-nc-id="SectionGridFilterCard"
        >
          <Heading3  />
          <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {DEMO_CATS.map((stay) => (
              <div className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-lg overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`} data-nc-id="StayCard">
                <div className="relative w-full">
                  <div className={`nc-GallerySlider ${className}`} data-nc-id="GallerySlider">
                    <div className={`relative group overflow-hidden`}>
                      <div className="glide__track" data-glide-el="track">
                        <ul className="glide__slides">
                          <li className="glide__slide">
                            <div className={`nc-NcImage`} data-nc-id="NcImage">
                              <img src={stay.thumbnail} className=" object-fill w-full h-full imgStyle" />
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"p-4 space-y-4"}>
                  <div className="space-y-2">
                    <div className="items-center space-x-2">
                      <h2
                        className={` font-medium capitalize text-lg`}
                      >
                        <span className="line-clamp-1 text-center">{stay.name}</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="flex mt-16 justify-center items-center">
            {/* <Pagination /> */}
          </div>
        </div>

      </div>
    </div>
  );
};

export default ListingTourPage;
