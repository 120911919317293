import React from "react";
import Button, { ButtonProps } from "shared/Button/Button";

export interface ButtonPrimaryProps extends ButtonProps {
  href?: string; // Making href optional
}

const ButtonPrimary: React.FC<ButtonPrimaryProps> = ({
  className = "",
  href, // Making href optional
  ...args
}) => {
  const handleClick = () => {
    if (href) {
      window.location.href = href;
    }
  };

  return (
    <Button
      className={`ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 ${className}`}
      onClick={handleClick}
      {...args}
    />
  );
};

export default ButtonPrimary;
