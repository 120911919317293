import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CommonLayout from "containers/PageAddListing1/CommonLayout";
import Input from "shared/Input/Input";
import FormItem from "containers/PageAddListing1/FormItem";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import { useParams } from "react-router-dom";
import { getFleetsById } from "services/apiServices";
import NcImage from "shared/NcImage/NcImage";
import { getAvatar } from "services/apiServices";
import FleetsreqCard from "./FleetsreqCard";
import { CarDataType } from "data/types";

export interface FleetsRequestProps {
    className?: string;
    data?: CarDataType[];
}



const FleetsRequest: FC<FleetsRequestProps> = ({ className = "" }) => {



    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [message, setMessage] = useState("");

    const [fleetsData, setFleetsData] = useState<any>([]);

    // const [avatarFile, setAvatarFile] = useState<any>([]);
    // const [profileImg, setProfileImg] = useState<any>("");

    // const getProfile = async(filename: any) => {
    //     try {
    //         const profile: any = await getAvatar(filename);
    // 	    setProfileImg(URL.createObjectURL(profile))
    //     } catch (error) {
    //         setProfileImg("")
    //     }
    // }

    // useEffect(() => {
    //     if(avatarFile.length === 0){
    // 	    getProfile(featuredImage);
    //     }
    // },[featuredImage])



    const handleRequestNow = async () => {

        const requestData = {
            email,
            message,
            name
        };

    };

    const { id } = useParams();


    const fetchFleetsData = async () => {
        try {
            const response = await getFleetsById(id)
            // console.log(response.data)

            if (response.data.response === "success") {
                setFleetsData(response.data.fleets)
                // console.log(response.data.fleets)
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        fetchFleetsData();
    }, []);

    const renderFleetsData = () => {
        return (

            <>
                {/* {(data as any)?.map((car: any) =>
                    <FleetsreqCard data={fleetsData} />
                )} */}
                {/* {console.log(fleetsData[0])} */}
                <FleetsreqCard data={fleetsData[0]} />


            </>
        )
    }

    const renderRequestForm = () => {
        return (
            <>
                <div>
                    <Helmet>
                        <title>FLEETS REQUEST | EUROPE TOURS & TRAVELS</title>
                    </Helmet>


                    <div className=" container w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
                        <h2 className="text-2xl font-semibold">Request Your Desired Fleet</h2>
                        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                        {/* FORM */}
                        <div className="space-y-8">
                            <FormItem
                                label="Your Name"
                                desc=""
                            >
                                <Input placeholder="John Doe" />
                            </FormItem>
                            <FormItem
                                label="Your E-mail"
                            >
                                <Input placeholder="Email Address" type="email" />
                            </FormItem>
                            <FormItem
                                label="Your Phone Number"
                                desc=""
                            >
                                <Input
                                    placeholder="Phone Number "
                                    type="tel"
                                    pattern="\(\d{3}\) \d{3}-\d{4}"
                                />
                            </FormItem>

                        </div>
                        <div className=" items-center justify-center">
                            <ButtonPrimary className=" justify-items-center " onClick={handleRequestNow}>Request Now</ButtonPrimary>
                        </div>
                    </div>






                </div>
            </>
        )
    }

    return (
        <>
            <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
                <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderRequestForm()}</div>
                <div className=" lg:block flex-grow">{renderFleetsData()}</div>
            </main>
        </>

    );
};

export default FleetsRequest;
