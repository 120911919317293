import React, { FC } from "react";
import { DEMO_CAR_LISTINGS } from "data/listings";
import { CarDataType } from "data/types";
//import Pagination from "shared/Pagination/Pagination";
import Heading2 from "components/Heading/Heading2";
import TourPacageCard from "./TourPacageCard";

export interface TourPacageCardGridProps {
  className?: string;
  data?: CarDataType[];
}

const DEMO_DATA: CarDataType[] = DEMO_CAR_LISTINGS.filter((_, i) => i < 12);

const TourPacageCardGrid: FC<TourPacageCardGridProps> = ({
  className = "",
  data = DEMO_DATA,
}) => {
  return (
    <div
      className={`nc-TourPacageCardGrid ${className}`}
      data-nc-id="TourPacageCardGrid"
    >
      <Heading2
        heading="Tour Packages"
        /* subHeading={
          <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
            233 cars
            <span className="mx-2">·</span>
            Aug 12 - 18
          </span>
        } */
      />
      <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
      {(data as any)?.map((car: any) => {
          return <TourPacageCard key={car.id} data={car}  />
        })}
      </div>
      {/* <div className="flex mt-16 justify-center items-center">
        <Pagination />
      </div> */}
    </div>
  );
};

export default TourPacageCardGrid;
