import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
// import FixedDepartureHero from "./components/FixedDepartureHero";
// import FixedDepartureCardGrid from "./components/FixedDepartureCardGrid";
import OneDayTourCardGrid from "./components/OneDayTourCardGrid";

import OneDayTourHero from "./components/OneDayTourHero";
import { getOneDayToursByCity } from "services/apiServices";

import { useLocation } from 'react-router-dom';

export interface OneDayTourPageProps {
  className?: string;
}


const OneDayTourPage: FC<OneDayTourPageProps> = ({ className = "" }) => {


  const [oneDayTourData, setOneDayTourData] = useState<any>([]);
  const location = useLocation();
  const props = location.state;

  const fetchFixedDepartureData = async () => {

    const response = await getOneDayToursByCity({ city:props.city, country: props.country })


    if (response.data) {
      let oDayTempData: any = [];
      if (response.data.response === "success") {
        console.log("oday Data", response.data)
        response.data.oneDayTour.map(async (item: any, key: number) => {
          oDayTempData[key] = {
            id: item.id,
            package_name: item.package_name,
            profileImg: item.image,
            seats: item.available_seats
          }
          console.log("xxxx", oDayTempData)
          setOneDayTourData(oDayTempData)
        })
      }
    }
  }


  useEffect(() => {
    fetchFixedDepartureData()
  }, [])

  return (
    <div
      className={`nc-OneDayTourPage relative overflow-hidden ${className}`}
      data-nc-id="OneDayTourPage"
    >
      <Helmet>
        <title>ONE DAY TOURS | EUROPE TOURS & TRAVELS</title>
      </Helmet>

      <OneDayTourHero />

      <div className="container relative  mt-4 md:mt-0 lg:mt-0 xl:mt-0">


        {oneDayTourData.length > 0 &&
          <OneDayTourCardGrid
            data={oneDayTourData}
            className="pb-24 lg:pt-32 lg:pb-16" />
        }

      </div>

    </div>
  );
};



export default OneDayTourPage
