import React from 'react'
import OurProductsHero from './components/OurProductsHero'
import SectionSliderNewCategories from './components/SectionSliderNewCategories'
import { TaxonomyType } from 'data/types';
import Queen from '../../images/queen-of-liberty-statue-of-liberty-new-york-liberty-statue-64271.webp';
import Tours from '../../images/tour.webp'
import van from '../../images/private-van-hire-image.jpg'
import oneD from '../../images/oneday.webp'
const DEMO_CATS: TaxonomyType[] = [
    // {
    //   id: "1",
    //   href: "/fixed-departure",
    //   name: "Fixed Departure",
    //   taxonomy: "category",
    //   thumbnail:"https://images.pexels.com/photos/2581922/pexels-photo-2581922.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
    // },
    {
      id: "2",
      href: "/tour-package",
      name: "Tour Pacakages",
      taxonomy: "category",

      thumbnail:Tours,
    },
    {
      id: "3",
      href: "/van-tour",
      name: "Van Tours",
      taxonomy: "category",

      thumbnail:van,
    },
    {
      id: "4",
      href: "/one-day-tour",
      name: "One Day Tours",
      taxonomy: "category",

      thumbnail:oneD,
    }
  ];

const OurProductsPage = () => {
  return (
    <>
        <OurProductsHero/>
        <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">

        <SectionSliderNewCategories
         categories={DEMO_CATS}
         uniqueClassName="products"
         categoryCardType='card4'
         heading='Our Products'
         className=' mx-16 mt-20'
         subHeading=''
         sliderStyle='style2'

          />
    </div>
    </>

  )
}

export default OurProductsPage