import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage1";
import { TaxonomyType } from "data/types";
import React, { FC, useState, useEffect } from "react";
import SectionGridFilterCard from "./SectionGridFilterCard";
import { Helmet } from "react-helmet";
import heroRightImage from "images/hero-right-car.png";
import SectionHero from "./TransportHero";
import { ourFleetsApi } from "services/apiServices";

export interface ListingTransportPageProps {
  className?: string;
}

const ListingTransportPage: FC<ListingTransportPageProps> = ({ className = "" }) => {
  const [fleetsData, setFleetsData] = useState<any>([]);

  const fetchFleetsData = async () => {
    const response = await ourFleetsApi()

    if (response.data) {
      let fleetstempData: any = [];
      if (response.data.response === "success") {
        console.log("Fleets Data", response.data)

        response.data.fleets.map(async (item: any, key: number) => {
          fleetstempData[key] = {
            id: item.id,
            title: item.brand,
            model: item.model,
            featuredImage: item.image,
            seats: item.pax_count,


          }
          // console.log("restdata",fleetstempData)
          setFleetsData(fleetstempData)
        })


      }
    }
  }

  useEffect(() => {
    fetchFleetsData()
  }, []);

  return (
    <div
      className={`nc-ListingTransportPage relative overflow-hidden ${className}`}
      data-nc-id="ListingTransportPage"
    >
      <Helmet>
        <title>FLEETS | EUROPE TOURS & TRAVELS</title>
      </Helmet>
      <BgGlassmorphism />

      <SectionHero />

      <div className="container relative mt-4 md:mt-0 lg:mt-0 xl:mt-0">
        {/* SECTION */}
        {/* <SectionGridFilterCard className="pb-24  lg:pt-32 lg:pb-16" /> */}

        {fleetsData.length > 0 &&
          <SectionGridFilterCard
            data={fleetsData}
            className="pb-24 lg:pt-32 lg:pb-16" />
        }


      </div>
    </div>
  );
};

export default ListingTransportPage;
