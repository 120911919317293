
import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import img from '../../../images/about1.jpg'
import { getAvatar } from 'services/apiServices';








const CityCard = ({ data, type, fetchData }: any) => {

    const navigate = useNavigate()
    const [hovered, setHovered] = useState(false);



    const [profileImg, setProfileImg] = useState("");
    const getProfile = async (filename: any) => {
        try {
            const profile: any = await getAvatar(filename);
            setProfileImg(URL.createObjectURL(profile))
        } catch (error) {
            // setProfileImg('../../../img/avatars/no_profile.jpg')
        }
    }

    useEffect(() => {
        getProfile(data.image);
    }, [])






    return (
        <>

            <div onClick={() => { navigate(`/one-day-tour-list`,{ state: { city: data.city, country: data.country } })  }} className={`bg-gray-900 shadow-lg rounded-2xl border overflow-hidden cursor-pointer z-1  hover:scale-110  duration-300 hover:mx-4 `}>
                <div className="relative">
                    <img className={`w-full h-60 object-cover object-center ${hovered ? 'hovered-img' : 'opacity-60'} `} src={profileImg} alt="Image" />
                    <div className="absolute inset-0 flex items-center justify-center">
                        <p className={`text-white $ text-2xl font-semibold text-center ${hovered ? 'text-shadow-lg' : ''}`}>{data.city}</p>
                    </div>
                </div>

            </div>

        </>
    )
}

export default CityCard