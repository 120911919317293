import { CarDataType } from 'data/types';
import React, { FC, useEffect, useState } from "react";
import { DEMO_CAR_LISTINGS } from "data/listings";
import NcImage from 'shared/NcImage/NcImage';
import { getAvatar } from "services/apiServices";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

export interface FleetsreqCardProps {
    data?: CarDataType;
}

const FleetsreqCard = ({ data }: any) => {
    const [avatarFile, setAvatarFile] = useState<any>([]);
    const [profileImg, setProfileImg] = useState<any>("");

    console.log(data)

    useEffect(() => {
        const getProfile = async () => {
            try {
                const profile: any = await getAvatar(data?.image);
                setProfileImg(URL.createObjectURL(profile))
            } catch (error) {
                setProfileImg("")
            }
        };

        if (avatarFile.length === 0 && data?.image) {
            getProfile();
        }
    }, [avatarFile, data]);

    return (
        <div className="bg-slate-100 rounded-lg p-4 shadow-lg">
        <h2 className="text-2xl  mb-4 font-semibold">Requested Fleet</h2>
        <div className="flex flex-col sm:flex-row items-center">
            <div className="relative w-full sm:w-60 h-60 sm:h-auto mb-4 sm:mr-4 overflow-hidden rounded-lg">

                    <NcImage
                        src={profileImg}
                        alt="Car Image"
                        className="object-cover w-full h-full shadow-sm"
                    />

            </div>
            <div className="flex-1">
                <div className="mb-2 items-center justify-center">
                    <span className="text-sm text-gray-500 text-center ">{data?.model}</span>
                    <br/>
                    <span className=" text-lg font-medium text-center ">{data?.brand}</span>
                </div>
                <div className="mb-2">
                    <FontAwesomeIcon icon={faUser} className="mr-1" />
                    <span className="text-sm text-gray-500 text-center">{data?.pax_count} passengers</span>
                </div>
                <hr className="border-gray-300" />
                
            </div>
        </div>
    </div>

    );
}

export default FleetsreqCard;
