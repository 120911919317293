import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import SocialsList from "shared/SocialsList/SocialsList";
import { contactApi } from "services/apiServices";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./../../styles/style.css"

export interface PageContactProps {
  className?: string;
}

const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async () => {
    var data = {
      "email": email,
      "message": message,
      "name": name
    };
    const response = await contactApi(data);
    if (response.data) {
      if (response.data.response === "success") {
        toast(response.data.response);
        setTimeout(() => {
          window.location.reload();
        }, 1000)
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_CENTER
        });
      }
    }
  }

  return (
    <div className="py-6">
      <ToastContainer />
      <Helmet>
        <title>CONTACT | EUROPE TOURS & TRAVELS</title>
      </Helmet>
      <div className="flex flex-col lg:flex-row bg-white rounded-lg overflow-hidden mx-auto max-w-sm lg:max-w-4xl">
        <div className="lg:flex-1 bg-cover bg-signup p-8 shadow-md">
          <div className="max-w-sm space-y-2 bg-neutral-100 p-4 ">
            <div className=" shadow-sm rounded-lg bg-neutral-200 px-5">
              <div >
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  🗺 Italy Office
                </h3>
                <span className="block text-neutral-500 dark:text-neutral-400">
                  Europe Tours & Travels, Via Abruzzi 94 Milan 20131 Italy
                </span>
              </div>
              <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  ☎ Italy PHONE
                </h3>
                <span className="block text-neutral-500 dark:text-neutral-400">
                  <a href="tel:+393516093653"> +39 351 609 3653</a>
                </span>
              </div>
            </div>
          </div>
          <div className="max-w-sm space-y-2 bg-neutral-100 p-4">
            <div className=" shadow-sm rounded-lg bg-neutral-200 px-5">
              <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  🗺 Swiss Office
                </h3>
                <span className="block text-neutral-500 dark:text-neutral-400">
                  Europe Tours & Travels, Richtistrasse 7 Zurich 8304 Switzerland
                </span>
              </div>
              <div className=" mb-4">
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  ☎ Swiss PHONE
                </h3>
                <span className="block text-neutral-500 dark:text-neutral-400">
                  <a href="tel:+41779983645">+41 77 998 36 45</a>
                </span>
              </div>
            </div>

            <div className=" mt-9 px-5">
              <div className=" mb-4">
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  💌 EMAIL
                </h3>
                <span className="block text-neutral-500 dark:text-neutral-400">
                  info@ettravels.com
                </span>
              </div>
              <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                🌏 SOCIALS
              </h3>
              <SocialsList className="mt-2" />
            </div>
          </div>
        </div>

        <div className="lg:flex-1 p-8">
          <h2 className="text-2xl font-semibold text-red-500 text-center textColor">
            Contact Us
          </h2>
          <div className="mt-4 flex items-center justify-between">
            <span className="border-b w-1/5 lg:w-1/4"></span>
            <a href="#" className="text-xs text-center text-gray-500 uppercase">
              or Contact with email
            </a>
            <span className="border-b w-1/5 lg:w-1/4"></span>
          </div>
          <form onSubmit={(e) => {
            e.preventDefault();
            handleSubmit()
          }}>
            <div className="mt-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Your Name
              </label>
              <input
                className="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none"
                type="text"
                placeholder="Username"
                onChange={(e) => {
                  setName(e.target.value);
                }}
                required={true}
              />
            </div>
            <div className="mt-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Your Email
              </label>
              <input
                className="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none"
                type="email"
                placeholder="Email address"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                required={true}
              />
            </div>
            <div className="mt-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Message
              </label>
              <textarea
                className="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none"
                placeholder="Message"
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                required={true}
              ></textarea>
            </div>
            <div className="mt-8">
              <button className="bg-blue-700 text-white font-bold py-2 px-4 w-full rounded hover:bg-gray-600">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PageContact;
