import { NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";

const demoChildMenus: NavItemType[] = [
  // {
  //   id: ncNanoId(),
  //   href: "/fixed-departure",
  //   name: "Fixed Departure",

  // },
  // {
  //   id: ncNanoId(),
  //   href: "/tour-package",
  //   name: "Tour Packages",
  //   isNew: true,
  // },
  {
    id: ncNanoId(),
    href: "/van-tour",
    name: "Van Tours",
    // isNew: true,
  },
  {
    id: ncNanoId(),
    href: "/one-day-tour",
    name: "One Day Tours",
    // isNew: true,
  },
];


export const NAVIGATION_DEMO: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Home",

    isNew: true,
  },
  {
    id: ncNanoId(),
    href: "/about",
    name: "About",

    isNew: true,
  },
  {
    id: ncNanoId(),
    href: "our-products",
    name: "Our Produts",
    type: "dropdown",
    children: demoChildMenus,

  },
  {
    id: ncNanoId(),
    href: "/ourfleets",
    name: "Our Fleets",

    isNew: true,
  },
  // {
  //   id: ncNanoId(),
  //   href: "/davos",
  //   name: "Davos",
  //   children:[],
  //   isNew: true,
  // },
  {
    id: ncNanoId(),
    href: "/film",
    name: "Film Production",

    isNew: true,
  },
  {
    id: ncNanoId(),
    href: "/contact",
    name: "Contact",

    isNew: true,
  },
];


