import rightImg from "images/about-hero-right.png";
import React, { FC } from "react";
import { Helmet } from "react-helmet";
import SectionAboutus from "components/SectionAboutus/SectionAboutus";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHero from "./SectionHero";
import AboutHero from "./AboutHero";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>ABOUT US | EUROPE TOURS & TRAVELS</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <AboutHero className="pt-10 lg:pt-16 lg:pb-16" />

      <div className="container py-16 space-y-16 lg:space-y-28">
        {/* <SectionHero
          rightImg={rightImg}
          heading="👋 About Us."
          btnText="GHGN"
          subHeading="We have built our company based on our strong connections with Travel Agents throughout the world. We pride ourselves in offering a service to Travel Agents that is professional, efficient, fast and reliable."
        /> */}
        <SectionAboutus/>
      </div>
    </div>
  );
};

export default PageAbout;
