import FooterLogo from "shared/Logo/FooterLogo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import Android from "../../images/btn-android.png"
import Ios from "../../images/btn-ios.png"
import React from "react";
import Input from "shared/Input/Input";
import ButtonCircle from "shared/Button/ButtonCircle";
import "../Footer/footer.css";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "5",
    title: "Europe Tours & Travels",
    menus: [
      { href: "/", label: "Home" },
      { href: "tour-pacage", label: "Tours Packages" },
      { href: "transportation", label: "Fleets" },
      { href: "fixed-departure", label: "Fixed Departure" },
      // { href: "contact", label: "Contacts" },

    ],
  },
  
  {
    id: "1",
    title: "Contact",
    menus: [
      { href: "#", label: "Europe Tours & Travels," },
      { href: "#", label: " Via Abruzzi 94 Milan 20131 Italy" },
      { href: "", label: "+393516093653" },
      { href: "#", label: "" },
      { href: "#", label: "info@ettravels.com" },
    ],
  },
  
];

const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200"  style={{color:"#fff"}}>
          {menu.title}
        </ h2>
        <ul className="mt-5 space-y-4" style={{color:"#fff"}}>
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                href={item.href}
                style={{color:"#fff"}}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="nc-Footer relative py-24 lg:py-28 border-t border-neutral-200 dark:border-neutral-700 cutdiv" style={{backgroundColor:'#001253',paddingBottom:"0rem"}}>
      <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-3 lg:grid-cols-4 lg:gap-x-10 ">
        <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-1 mr-7" >
            <FooterLogo />
          </div>
          <div className="col-span-2 flex items-center md:col-span-3">
            <SocialsList1 className="flex items-center space-x-3 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start"/>
          </div>
        </div>
        {widgetMenus.map(renderWidgetMenuItem)}
        <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-1">
          <form className="relative max-w-sm">
          <Input
            required
            aria-required
            placeholder="Enter your email"
            type="email"
            rounded="rounded-full"
          />
          <ButtonCircle
            type="submit"
            className="absolute transform top-1/2 -translate-y-1/2 right-[5px]"
          >
            <i className="las la-arrow-right text-xl"></i>
          </ButtonCircle>
        </form>
          </div>
          <div className="col-span-2 flex items-center md:col-span-3 space-x-5">
          <div className="col-span-2 md:col-span-1">
            <img src={Android} alt="android"/>
          </div>
          <div className="col-span-2 md:col-span-1">
            <img src={Ios} alt="mac"/>
          </div>
          </div>
        </div>
      </div>
      <p className=" text-xs" style={{color:"#fff", textAlign:"right",padding:"20px"}}> © 2024 <a href="#" className="link">Europe Tours & Travels</a> . All rights reserved.</p>
    </div>
  );
};

export default Footer;
