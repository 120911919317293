import Heading from "components/Heading/Heading";
import React, { FC } from "react";
import Business from "./images/business.jpg";
import Event from "./images/event.jpg"
import Flim from "./images/film.jpg"
import Transport from "./images/transportaton.jpg"
import Hotel from "./images/hotel.jpg"
import Apart from "./images/apart.jpg"
import Car from "./images/car_rental1.png"
import catering from "./images/catering.jpg"
import coach from "./images/coach_rental.png"
import tickets from "./images/tickets.jpg"
import guide from "./images/tour_guide.jpeg"
import tours from "./images/tours.jpg"

export interface SectionServiceProps {
  className?: string;
  data?: {
    id: number;
    title: string;
    desc: string;
    img: string;
    imgDark?: string;
  }[];
}

const DEMO_DATA: SectionServiceProps["data"] = [
  {
    id: 1,
    img: Business,
    title: "BUSINESS TRAVEL",
    desc: "If your client is planning a conference, a wedding, a yoga retreat or a school tour, we can make all the arrangements for you. Our staff have the expertise and contacts for all special events and special interest tours",
  },
  {
    id: 2,
    img: Event,
    title: "EVENT MANAGEMENT",
    desc: "If your client is planning a conference, a wedding, a yoga retreat or a school tour, we can make all the arrangements for you. Our staff have the expertise and contacts for all special events and special interest tours.",
  },
  {
    id: 3,
    img: Flim,
    title: "FILM LINE PRODUCTION",
    desc: "As with all our special interest tours. We are doing FILM LINE PRODUCTION also, We have contacts all over the Europe regarding locations and permits, staff and catering. We will be delighted to help your Project.",
  },
  {
    id: 4,
    img: Transport,
    title: "TRANSPORTATIONS",
    desc: "We have partnerships and connections with private drivers and guides all over Europe. Whether you want an airport transfer, a half-day or a full day driver at your disposal, we can arrange it.",
  },
  {
    id: 5,
    img: Hotel,
    title: "HOTELS BOOKINGS",
    desc: "We manage and book all aspects of accommodation and have excellent buying power with deluxe hotels, boutique hotels, Whether you want one night or one month, have an FIT booking or a group booking, we will be delighted to handle the arrangements for you. And our rates will always be competitive.",
  },
  {
    id: 6,
    img: Apart,
    title: "APARTMENTS",
    desc: "If you are looking for a long stay for your clients, have business travellers or a family, we have an excellent range of apartments, ranging from studios to penthouses. Catered, or self catering, we will handle these arrangements for you.",
  },
  {
    id: 7,
    img: coach,
    title: "COACH RENTAL",
    desc: " Group travel can be particularly demanding. Let us take over all aspects of the group for you, including the coach rental. Whether you need a small van or a 80 seater coach, we have the contacts. And expertise.",
  },
  {
    id: 8,
    img: Car,
    title: "CAR RENTAL",
    desc: "Not only will we arrange your car hire but we will remind you about International driving licenses and chat to you about all the insurances. Long and short term rentals, budget and luxury cars - we can do it all.",
  },

  {
    id: 9,
    img: guide,
    title: "TOUR GUIDE",
    desc: "Very often a trip, business or leisure, is dependant on the tour guide. We have an excellent range of tour guides all over the Europe and will always give you the best. Of course we will talk to you about language too.",
  },

  {
    id: 10,
    img: tickets,
    title: "TICKETS",
    desc: "Sightseeing is often easier if tickets are booked in advance, particularly in high season. We have access to sightseeing tickets all over the Europe and will happily arrange your cable car, museum entry, ferry ticket, train ticket or theatre ticket too. One person? No problem. A group? No problem either..",
  },
  {
    id: 11,
    img: catering,
    title: "CATERING SERVICES",
    desc: "Our clients very often have special dietary requirements. Whether you are on a low fat diet, gluten-free, vegetarian, vegan, Halaal or kosher, we can assist. If you need catering for a large event, please talk to us too.",
  },


  {
    id: 12,
    img: tours,
    title: "TOURS & SIGHTSEEING",
    desc: "We book scheduled tours and private tours and will always give you the options. If your client is on a tight budget, we recommend schedule. If they have time, private. We will prebook your tours and sightseeing arrangements. Walking tours are our speciality too.",
  },
];



const SectionService: FC<SectionServiceProps> = ({
  className = "",

}) => {
  return (
    <div
      className={`nc-SectionService  ${className}`}
      data-nc-id="SectionService"
    >

      <Heading isCenter desc="What we do">
        Services

      </Heading>
      {/* grid start  */}
      <div className="grid xl:grid-cols-4 lg:grid-cols-4 grid-cols-1 gap-6   pt-10  lg:mx-32 mx-10 ">
        {/* grid content  */}


        {DEMO_DATA.map((item:any) => {
          return (
            <div className="max-w-sm rounded overflow-hidden shadow-lg mb-14 ">
              <img className="w-full h-64" src={item.img} />
              <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2 text-center">{item.title}</div>
                <p className="text-gray-700 text-center justify-center  dark:text-white ">
                 {item.desc}
                </p>
              </div>
            </div>)
        })}




      

       

        

        {/* 

        

       

     

        

       

        

        <div className="max-w-sm rounded overflow-hidden shadow-lg mb-14 ">
          <img className="w-full h-64" src={Hotel} />
          <div className="px-6 py-4">
            <div className="font-bold text-xl mb-2 text-center">TOURS & SIGHTSEEING</div>
            <p className="text-gray-700  dark:text-white text-center justify-center">
              
            </p>
          </div>
        </div>  */}


      </div>





    </div>





  );
};

export default SectionService;
