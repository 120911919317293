import React, { useState, useEffect } from 'react'
import OneDaayTourCard from './components/OneDayTourCard'
import OneDayTourHero from "./components/OneDayTourHero";
import CityCard from './components/CityCard';
import { getAllDestination } from 'services/apiServices'
import { getAvatar } from 'services/apiServices';


const OneDayTournew = () => {


    const [data, setData] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);



    const fetchData = async () => {
        setLoading(true)
        try {
            const response: any = await getAllDestination();
            if (response.data.response === 'success') {
                console.log(data)
                setData(response.data.data.rows)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData();
    }, [])




    return (
        <div>
            <OneDayTourHero className='mb-11' />

            <div className='items-center text-center sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-7 px-0 sm:p-6 xl:p-8 mx-5 my-6 shadow-2xl'>

                    {/* <CityCard data={item} /> */}
                    {data.length > 0 ? <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-3 xl:grid-cols-3 gap-6 mx-10">
                        {data.map((item: any, index: any) => {
                            return <CityCard data={item} fetchData={fetchData} />
                        })}
                    </div> : <p> </p> }


            </div>
        </div>
    )
}

export default OneDayTournew