import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng from "images/hero-right.png";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";

export interface TourPacageHeroProps {
    className?: string;
}

const TourPacageHero: FC<TourPacageHeroProps> = ({ className = "" }) => {
    return (



        <div
            className={`nc-TourPacageHero flex px-10 relative ${className} dashboard-bg items-center justify-center`}
            data-nc-id="TourPacageHero"
        >
            <div className="flex flex-col lg:flex-row items-center justify-center">
                <div className="flex-shrink-0  flex flex-col items-center space-y-8 sm:space-y-10 pb-10 md:pb-14 lg:pb-64 xl:pr-14 ">
                    <h2 className="font-medium text-lg md:text-5xl xl:text-7xl text-gray-100 text-left
          ">
                        Tour Packages
                    </h2>
                    <span className="text-base md:text-lg text-gray-100 text-center">
                    We have built our company based on our strong connections with Travel Agents throughout the world.
                    </span>

                    {/* <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
Accompanying us, you have a trip full of experiences. With Chisfis, booking accommodation, resort villas, hotels
</span> */}
                    {/* <ButtonPrimary>Start your search</ButtonPrimary> */}
                </div>
                {/* <div className="flex-grow">
<img className="w-full" src={imagePng} alt="hero" />
</div> */}
            </div>

            <div className="hidden lg:block z-10 mb-2 lg:mb-0 lg:-mt-60  items-center">
                {/* <HeroSearchForm /> */}
            </div>
        </div>



    );
};

export default TourPacageHero;
