import React, { FC, Fragment, useEffect, useState } from "react";
import GuestsInput from "components/HeroSearchForm/GuestsInput";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Timeline from "components/TimeLine/TimeLine";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import { useParams } from "react-router-dom";
import { getOneDayToursById } from "services/apiServices";
import parse from 'html-react-parser'


const OneDayTourViewPage = ({
    className = "",

}) => {

    const [data, setData] = useState<any>([]);


    const { id } = useParams();

    const FetchData = async () => {
        try {
            const response = await getOneDayToursById(id)
            console.log(response.data)
            if (response.data.response === "success") {
                setData(response.data.oneDayTour)
            }
        } catch (error) {

        }
    }
    // console.log(data[0]?.fixedDeparture[0]?.notes);

    // console.log(id)
    useEffect(() => {
        FetchData()
    }, [])

    const renderSection2 = (items: any, heading: any) => {
        return (
            <div className="listingSection__wrap">
                <Timeline items={items} heading={heading} />
            </div>
        );
    };

    const renderInclusions = (items: any) => {
        console.log(items)
        return (
            <><div>

                    {parse(items)}

            </div>

            </>
        )
    }

    const renderExclusions = (items: any) => {
        console.log(items)
        return (
            <><div>
               {parse(items)}
            </div>

            </>
        )
    }


    const renderNotes = (items: any) => {
        return (
            <><div>
                {/* {items.map((item: any, index: number) => ( */}
                <div className="prose sm:prose">
                    <ul className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2 text-xs">
                        <li >
                            {parse (items)}
                        </li>

                    </ul>
                </div>
                {/* ))} */}
            </div>

            </>
        )
    }

    const renderSidebar = () => {
        return (
            <div className="listingSectionSidebar__wrap shadow-md">

                {/* FORM */}
                <div className="mt-5 text-center">
                    <p className="text-sm text-gray-600">
                        For more details, Contact us! <br />

                    </p>
                    <a href="tel:+393516093653">
                        <ButtonPrimary className="mt-3 rounded-md">
                            +393516093653
                        </ButtonPrimary>
                    </a>
                </div>
                <div className=" space-y-5">
                    <h4 className="text-lg font-semibold">Inclusions</h4>
                    {data.length > 0 && renderInclusions(data[0].inclusions)}
                    {/* {renderInclusions()} */}

                    <h4 className="text-lg font-semibold">Exclusions</h4>
                    {data.length > 0 && renderExclusions(data[0].exclusions)}


                    <h4 className="text-lg font-semibold">Notes</h4>
                    {/* {data.length > 0 && renderNotes(data[0].fixedDeparture[0].notes)} */}
                    {/* {data.length > 0 && data[0]?.fixedDeparture  && renderNotes(data[0].fixedDeparture[0].notes)} */}
                    {data.length > 0 && renderNotes(data[0].notes)}




                </div>
            </div>
        );
    };

    return (
        <div
            className={`ListingDetailPage nc-OneDayTourViewPage ${className}`}
            data-nc-id="OneDayTourViewPage"
        >
            <main className="container z-10 mt-11 flex flex-col lg:flex-row mb-6">
                {/* CONTENT */}
                <div className="lg:pr-5 lg:w-4/6">

                    {data.length > 0 && renderSection2(data[0].itinerary, data[0].package_name)}

                </div>

                {/* SIDEBAR */}
                <div className="lg:block flex-grow mt-14 lg:mt-0 lg:w-1/6">
                    <div className=" top-28">{renderSidebar()}</div>
                </div>
            </main>


            {/* <div className=" relative container">
                <SectionSliderNewCategories

                    uniqueClassName="PageHome_s1"
                    className="mb-5"
                    categoryCardType="card3"
                />
            </div> */}




        </div>
    );
};

export default OneDayTourViewPage;




