
import React, { useState, useEffect } from 'react'
import '../components/cardComponent.css';
import { getAvatar } from 'services/apiServices';
import { useNavigate } from "react-router-dom";
import ButtonPrimary from 'shared/Button/ButtonPrimary';

const FixDepCard = ({ data }: any) => {


  const [profileImg, setProfileImg] = useState("");
  const getProfile = async (filename: any) => {
    try {
      const profile: any = await getAvatar(filename);
      setProfileImg(URL.createObjectURL(profile))
    } catch (error) {
      // setProfileImg('../../../img/avatars/no_profile.jpg')
    }
  }

  useEffect(() => {
    getProfile(data.profileImg);
  }, [])

  const navGate = useNavigate()

  return (
    <div className="tour-card dark:bg-gray-900 shadow-lg justify-center" style={{ height: '250px' }} onClick={() => navGate(`/fixed-departure/view/${data.id}`)}>
      <div className="tour-card-image" style={{ backgroundImage: `url(${profileImg})` }}></div>
      <div className="tour-card-content">
        <h2 className="tour-text-bold">{data.package_name}</h2>
        {/* <p>{data.itinerary}</p> */}
      </div>

      <div className="flex justify-between tour-card-content">
        <span className="">
          Available Seats {data.seats}
        </span>
        <div>
          <ButtonPrimary className=' rounded-md py-0 px-0'>View More</ButtonPrimary>
        </div>
      </div>

    </div>
  )
}

export default FixDepCard

