import React, { useEffect, useState, FC } from "react";
import { DEMO_CAR_LISTINGS } from "data/listings";
import { CarDataType } from "data/types";
//import Pagination from "shared/Pagination/Pagination";
import Heading2 from "components/Heading/Heading2";
import VanTourCard from "./VanTourCard";


export interface VantourCardgridCardProps {
  className?: string;
  data?: CarDataType[];
}

const DEMO_DATA: CarDataType[] = DEMO_CAR_LISTINGS.filter((_, i) => i < 12);

const VantourCardgridCard: FC<VantourCardgridCardProps> = ({
  className = "",
  data = DEMO_DATA,
}) => {
  return (
    <div
      className={`nc-VantourCardgridCard ${className}`}
      data-nc-id="VantourCardgridCard"
    >
      <Heading2
        heading="Our Van Tours"
        
      />
      <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
        {(data as any)?.map((car:any) =>  
           <VanTourCard key={car.id} data={car}  size="small"/> 
        )}
       

        
      </div>
      {/* <div className="flex mt-16 justify-center items-center">
        <Pagination />
      </div> */}
    </div>
  );
};

export default VantourCardgridCard;
