import React, { FC } from "react";



export interface DavosHeroProps {
  className?: string;
}

const DavosHero: FC<DavosHeroProps> = ({ className = "" }) => {
  return (



    <div
      className={`nc-DavosHero flex px-10 relative ${className} dashboard-bg items-center justify-center justify-center`}
      data-nc-id="DavosHero"
    >
      <div className="flex flex-col lg:flex-row items-center justify-center">
        <div className="flex-shrink-0  flex flex-col items-center space-y-8 sm:space-y-10 pb-10 md:pb-14 lg:pb-64 xl:pr-14 ">
          <h2 className="font-medium text-lg md:text-5xl xl:text-7xl text-gray-100">
            VVIP Transfers
          </h2>
          <span className="text-base md:text-lg text-gray-100">
            We have built our company based on our strong connections with Travel Agents throughout the world.
          </span>

          {/* <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
Accompanying us, you have a trip full of experiences. With Chisfis, booking accommodation, resort villas, hotels
</span> */}
          {/* <ButtonPrimary>Start your search</ButtonPrimary> */}
        </div>
        {/* <div className="flex-grow">
<img className="w-full" src={imagePng} alt="hero" />
</div> */}
      </div>

      <div className="hidden lg:block z-10 mb-2 lg:mb-0 lg:-mt-60  items-center">
        {/* <HeroSearchForm /> */}
      </div>
    </div>



  );
};

export default DavosHero;
