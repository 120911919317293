// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/heroimg.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dashboard-bg {\n    background-repeat: no-repeat;\n    background-size:contain;\n    background-position:center;\n    background:linear-gradient(#1B1f34, rgba(0, 0, 0, 0)), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center center no-repeat;\n    height:50vh;\n    width: 100vw;\n}\n\n.dashboard1-bg {\n    background-repeat: no-repeat;\n    background-size:contain;\n    background-position:center;\n    background:linear-gradient(#1B1f34, rgba(0, 0, 0, 0)), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center center no-repeat;\n    height:88vh;\n    width: 100vw;\n}\n\n", "",{"version":3,"sources":["webpack://./src/css/main.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B,uBAAuB;IACvB,0BAA0B;IAC1B,sHAAyG;IACzG,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,4BAA4B;IAC5B,uBAAuB;IACvB,0BAA0B;IAC1B,sHAAyG;IACzG,WAAW;IACX,YAAY;AAChB","sourcesContent":[".dashboard-bg {\n    background-repeat: no-repeat;\n    background-size:contain;\n    background-position:center;\n    background:linear-gradient(#1B1f34, rgba(0, 0, 0, 0)), url(../images/heroimg.jpg) center center no-repeat;\n    height:50vh;\n    width: 100vw;\n}\n\n.dashboard1-bg {\n    background-repeat: no-repeat;\n    background-size:contain;\n    background-position:center;\n    background:linear-gradient(#1B1f34, rgba(0, 0, 0, 0)), url(../images/heroimg.jpg) center center no-repeat;\n    height:88vh;\n    width: 100vw;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
